@import 'bootstrap/scss/_mixins';
@import "variables";

@mixin user-group-font {
  font-size: 12px;
  font-weight: normal;
}

@mixin button-large {
  width: 174px;
  height: 36px;
  border-radius: 2px;
}

@mixin button-middle {
  width: 146px;
  height: 32px;
  display: inline-block;
  text-decoration: none;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  letter-spacing: 3px;
}

@mixin group-count-font {
  font-size: 24px;
  font-weight: bold;
  vertical-align: sub;
  text-align: right;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin price-label() {
  content: "￥";
  font-weight: normal;
  position: absolute;
  display: inline-block;
}

@mixin disabled-form() {
  cursor: not-allowed;
  color: $deep-gray;
  border-radius: 2px;
  border: 1px solid $silver;
  background-color: $dividing-line;
}

@mixin display-flex($jc: flex-start, $ai: stretch) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

@mixin grid-gray-header-theme {
  .white.grid-wrapper {
    max-height: 190px;
    overflow: auto;
    .ag-header-row {
      background-color: $disable-background;
    }
    .ag-row-even {
      background-color: $white;
    }

    ::ng-deep .ag-row-odd {
      background-color: $wild-sand;
    }
  }
}

@keyframes blinkFont {
  0% {
    color: $black;
  }
  50% {
    color: $cerulean;
  }
  100% {
    color: $black;
  }
}

@mixin blink-font {
  animation: blinkFont linear 2s;
}
