/* stylelint-disable */
@import "variables";

.input-group.input-group-clear {
  > .input-group-addon {
    background: none;
    border: none;
    color: white;
  }
  > .form-control {
    background: none;
    border: none;
    color: white;
  }

  border-bottom: 1px solid white;
}

.form-control:focus {
  color: #000;
}

.btn-primary {
  background-color: #55C8EA;
}

.btn-primary:hover {
  background-color: #51BEDE;
}

.btn-success {
  background-color: #13CFA5;
}

.btn-success:hover {
  background-color: #11B994;
}

.btn-default {
  color: $gray-darker;
  background-color: #D7DCE5;
}

.btn-default:hover {
  background-color: #CCD1DA;
}

.btn {
  border-radius: 3px;
  font-weight: bold;

  &:hover {
    outline: none;
    outline-offset: -0;
  }
  &:active {
    outline: none;
    outline-offset: -0;
  }
  &:focus {
    outline: none;
    outline-offset: -0;
  }
  &:active:hover {
    outline: none;
    outline-offset: -0;
  }
  &:active:focus {
    outline: none;
    outline-offset: -0;
  }
  &:disabled,
  &.disabled {
    pointer-events: auto;
    border: 1px solid transparent;
  }
}

.btn-primary {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-bg;
  --bs-btn-disabled-border-color:$btn-primary-bg;
  &:hover {
    background-color: $btn-primary-hover;
    border-color: $btn-primary-hover;
  }
  &:active {
    background-color: $btn-primary-active;
    border-color: $btn-primary-active;
  }
  &:focus {
    background-color: $btn-primary-hover;
    border-color: $btn-primary-hover;
  }
  &:active:hover {
    background-color: $btn-primary-hover;
    border-color: $btn-primary-hover;
  }
  &:active:focus {
    background-color: $btn-primary-hover;
    border: none;
  }
  &:disabled,
  &.disabled,
  &.disabled:hover,
  &:disabled:hover{
    background-color: $btn-primary-bg;
    cursor: not-allowed;
    opacity: .65;
    border: 1px solid transparent;
  }
}

.btn-success {
  background-color: $btn-success-bg;
  &:hover {
    background-color: $btn-success-hover;
  }
  &:active {
    background-color: $btn-success-hover;
  }
  &:focus {
    background-color: $btn-success-hover;
  }
  &:active:hover {
    background-color: $btn-success-hover;
  }
  &:active:focus {
    background-color: $btn-success-hover;
  }
  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-default {
  color: $dusty-gray;
  background-color: $btn-default-bg;
  &:hover {
    background-color: $btn-default-hover;
  }
  &:active {
    background-color: $btn-default-hover;
  }
  &:focus {
    background-color: $btn-default-hover;
  }
  &:active:hover {
    background-color: $btn-default-hover;
  }
  &:active:focus {
    background-color: $btn-default-hover;
  }
  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-danger {
  color: $dusty-gray;
  background-color: $btn-danger-bg;
  &:hover {
    background-color: $btn-danger-hover;
  }
  &:active {
    background-color: $btn-danger-hover;
  }
  &:focus {
    background-color: $btn-danger-hover;
  }
  &:active:hover {
    background-color: $btn-danger-hover;
    border-color: $btn-danger-hover;
  }
  &:active:focus {
    background-color: $btn-danger-hover;
  }
  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-info {
  color: #fff;
  background-color: $btn-info-bg;
  border-color: $btn-info-bg;
  &:hover,&:active,&:focus,&:active:focus {
    color: #fff;
    background-color: $btn-info-hover;
  }
  &:active:hover {
    color: #fff;
    background-color: $btn-info-hover;
    border-color: $btn-info-hover-border;
  }
  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-outline-primary {
  color: $btn-primary-bg;
  background-color: transparent;
  border-color: $btn-primary-bg;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    color: #fff;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
  }
  &:disabled,
  &.disabled {
    color: $cerulean;

    &:hover {
      color: white;
      cursor: not-allowed;
    }
  }
}

.form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f5f5f5;
}

.page-item.disabled .page-link {
  color: #C1C1C1;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 100%;
}

@media (min-width: 720px) {
  .modal-dialog {
    max-width: 824px;
    margin: 30px auto;
  }
}

.table>:not(:first-child) {
  border-top: none;
}

.modal-header .btn-close {
  margin: 0;
  padding: 0;
}

a.page-link:focus,
a.page-link:hover {
  text-decoration: underline;
}

.tooltip .tooltip-arrow.arrow {
  display: none;
}
/* stylelint-enable */
