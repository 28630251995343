$antd-css-prefix: antd !default;

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.fa,
.#{$antd-css-prefix} {
  display: inline-block;
  font: 14px/1 antd;
  font-size: inherit;
  text-rendering: auto;
  vertical-align: text-bottom;
}

// Icon Sizes
// -------------------------

/* makes the font 33% larger relative to the icon container */
.#{$antd-css-prefix}-lg {
  font-size: calc(4em / 3);
  line-height: calc(3em / 4);
  vertical-align: -15%;
}
.#{$antd-css-prefix}-2x {
  font-size: 2em;
}
.#{$antd-css-prefix}-3x {
  font-size: 3em;
}
.#{$antd-css-prefix}-4x {
  font-size: 4em;
}
.#{$antd-css-prefix}-5x {
  font-size: 5em;
}

// Spinning Icons
// --------------------------
.#{$antd-css-prefix}-spin {
  -webkit-animation: antd-spin 2s infinite linear;
  animation: antd-spin 2s infinite linear;
}

.#{$antd-css-prefix}-pulse {
  -webkit-animation: antd-spin 1s infinite steps(8);
  animation: antd-spin 1s infinite steps(8);
}

@-webkit-keyframes antd-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes antd-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// Fixed Width Icons
// -------------------------
.#{$antd-css-prefix}-fw {
  width: calc(18em / 14);
  text-align: center;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  font: normal normal normal 14px/0.5 primeicons !important;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  content: "\e900" !important;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  font: normal normal normal 14px/0.5 primeicons !important;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  content: "\e901" !important;
}

@import './antd.scss';
