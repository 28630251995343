@font-face {
  font-family: "antd";
  src: url('antd.eot?t=1697611657526');
  src:
    url('antd.eot?t=1697611657526#iefix') format('embedded-opentype'),
    url("antd.woff2?t=1697611657526") format("woff2"),
    url("antd.woff?t=1697611657526") format("woff"),
    url('antd.ttf?t=1697611657526') format('truetype'),
    url('antd.svg?t=1697611657526#antd') format('svg');
}

[class^="antd-"],
[class*=" antd-"] {
  font-family: 'antd' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.antd-account-book-filled:before {
  content: "\ea01";
}

.antd-account-book-outlined:before {
  content: "\ea02";
}

.antd-aim-outlined:before {
  content: "\ea03";
}

.antd-alert-filled:before {
  content: "\ea04";
}

.antd-alert-outlined:before {
  content: "\ea05";
}

.antd-alibaba-outlined:before {
  content: "\ea06";
}

.antd-align-center-outlined:before {
  content: "\ea07";
}

.antd-align-left-outlined:before {
  content: "\ea08";
}

.antd-align-right-outlined:before {
  content: "\ea09";
}

.antd-alipay-circle-filled:before {
  content: "\ea0a";
}

.antd-alipay-circle-outlined:before {
  content: "\ea0b";
}

.antd-alipay-outlined:before {
  content: "\ea0c";
}

.antd-alipay-square-filled:before {
  content: "\ea0d";
}

.antd-aliwangwang-filled:before {
  content: "\ea0e";
}

.antd-aliwangwang-outlined:before {
  content: "\ea0f";
}

.antd-aliyun-outlined:before {
  content: "\ea10";
}

.antd-amazon-circle-filled:before {
  content: "\ea11";
}

.antd-amazon-outlined:before {
  content: "\ea12";
}

.antd-amazon-square-filled:before {
  content: "\ea13";
}

.antd-android-filled:before {
  content: "\ea14";
}

.antd-android-outlined:before {
  content: "\ea15";
}

.antd-ant-cloud-outlined:before {
  content: "\ea16";
}

.antd-ant-design-outlined:before {
  content: "\ea17";
}

.antd-apartment-outlined:before {
  content: "\ea18";
}

.antd-api-filled:before {
  content: "\ea19";
}

.antd-api-outlined:before {
  content: "\ea1a";
}

.antd-apple-filled:before {
  content: "\ea1b";
}

.antd-apple-outlined:before {
  content: "\ea1c";
}

.antd-appstore-add-outlined:before {
  content: "\ea1d";
}

.antd-appstore-filled:before {
  content: "\ea1e";
}

.antd-appstore-outlined:before {
  content: "\ea1f";
}

.antd-area-chart-outlined:before {
  content: "\ea20";
}

.antd-arrow-down-outlined:before {
  content: "\ea21";
}

.antd-arrow-left-outlined:before {
  content: "\ea22";
}

.antd-arrow-right-outlined:before {
  content: "\ea23";
}

.antd-arrow-up-outlined:before {
  content: "\ea24";
}

.antd-arrows-alt-outlined:before {
  content: "\ea25";
}

.antd-audio-filled:before {
  content: "\ea26";
}

.antd-audio-muted-outlined:before {
  content: "\ea27";
}

.antd-audio-outlined:before {
  content: "\ea28";
}

.antd-audit-outlined:before {
  content: "\ea29";
}

.antd-backward-filled:before {
  content: "\ea2a";
}

.antd-backward-outlined:before {
  content: "\ea2b";
}

.antd-bank-filled:before {
  content: "\ea2c";
}

.antd-bank-outlined:before {
  content: "\ea2d";
}

.antd-bar-chart-outlined:before {
  content: "\ea2e";
}

.antd-barcode-outlined:before {
  content: "\ea2f";
}

.antd-bars-outlined:before {
  content: "\ea30";
}

.antd-behance-circle-filled:before {
  content: "\ea31";
}

.antd-behance-outlined:before {
  content: "\ea32";
}

.antd-behance-square-filled:before {
  content: "\ea33";
}

.antd-behance-square-outlined:before {
  content: "\ea34";
}

.antd-bell-filled:before {
  content: "\ea35";
}

.antd-bell-outlined:before {
  content: "\ea36";
}

.antd-bg-colors-outlined:before {
  content: "\ea37";
}

.antd-block-outlined:before {
  content: "\ea38";
}

.antd-bold-outlined:before {
  content: "\ea39";
}

.antd-book-filled:before {
  content: "\ea3a";
}

.antd-book-outlined:before {
  content: "\ea3b";
}

.antd-border-bottom-outlined:before {
  content: "\ea3c";
}

.antd-border-horizontal-outlined:before {
  content: "\ea3d";
}

.antd-border-inner-outlined:before {
  content: "\ea3e";
}

.antd-border-left-outlined:before {
  content: "\ea3f";
}

.antd-border-outer-outlined:before {
  content: "\ea40";
}

.antd-border-outlined:before {
  content: "\ea41";
}

.antd-border-right-outlined:before {
  content: "\ea42";
}

.antd-border-top-outlined:before {
  content: "\ea43";
}

.antd-border-verticle-outlined:before {
  content: "\ea44";
}

.antd-borderless-table-outlined:before {
  content: "\ea45";
}

.antd-box-plot-filled:before {
  content: "\ea46";
}

.antd-box-plot-outlined:before {
  content: "\ea47";
}

.antd-branches-outlined:before {
  content: "\ea48";
}

.antd-bug-filled:before {
  content: "\ea49";
}

.antd-bug-outlined:before {
  content: "\ea4a";
}

.antd-build-filled:before {
  content: "\ea4b";
}

.antd-build-outlined:before {
  content: "\ea4c";
}

.antd-bulb-filled:before {
  content: "\ea4d";
}

.antd-bulb-outlined:before {
  content: "\ea4e";
}

.antd-calculator-filled:before {
  content: "\ea4f";
}

.antd-calculator-outlined:before {
  content: "\ea50";
}

.antd-calendar-filled:before {
  content: "\ea51";
}

.antd-calendar-outlined:before {
  content: "\ea52";
}

.antd-camera-filled:before {
  content: "\ea53";
}

.antd-camera-outlined:before {
  content: "\ea54";
}

.antd-car-blue-supplement:before {
  content: "\ea55";
}

.antd-car-filled-blue-supplement:before {
  content: "\ea56";
}

.antd-car-filled:before {
  content: "\ea57";
}

.antd-car-outlined:before {
  content: "\ea58";
}

.antd-caret-down-filled:before {
  content: "\ea59";
}

.antd-caret-down-outlined:before {
  content: "\ea5a";
}

.antd-caret-left-filled:before {
  content: "\ea5b";
}

.antd-caret-left-outlined:before {
  content: "\ea5c";
}

.antd-caret-right-filled:before {
  content: "\ea5d";
}

.antd-caret-right-outlined:before {
  content: "\ea5e";
}

.antd-caret-up-filled:before {
  content: "\ea5f";
}

.antd-caret-up-outlined:before {
  content: "\ea60";
}

.antd-carry-out-filled:before {
  content: "\ea61";
}

.antd-carry-out-outlined:before {
  content: "\ea62";
}

.antd-check-blue-supplement:before {
  content: "\ea63";
}

.antd-check-circle-filled-green-supplement:before {
  content: "\ea64";
}

.antd-check-circle-filled-white-supplement:before {
  content: "\ea65";
}

.antd-check-circle-filled:before {
  content: "\ea66";
}

.antd-check-circle-green-supplement:before {
  content: "\ea67";
}

.antd-check-circle-outlined:before {
  content: "\ea68";
}

.antd-check-circle-white-supplement:before {
  content: "\ea69";
}

.antd-check-outlined:before {
  content: "\ea6a";
}

.antd-check-square-blue-supplement:before {
  content: "\ea6b";
}

.antd-check-square-filled:before {
  content: "\ea6c";
}

.antd-check-square-outlined:before {
  content: "\ea6d";
}

.antd-chrome-filled:before {
  content: "\ea6e";
}

.antd-chrome-outlined:before {
  content: "\ea6f";
}

.antd-ci-circle-filled:before {
  content: "\ea70";
}

.antd-ci-circle-outlined:before {
  content: "\ea71";
}

.antd-ci-outlined:before {
  content: "\ea72";
}

.antd-circle-filled-black-supplement:before {
  content: "\ea73";
}

.antd-circle-grey-supplement:before {
  content: "\ea74";
}

.antd-clear-outlined:before {
  content: "\ea75";
}

.antd-clock-circle-filled:before {
  content: "\ea76";
}

.antd-clock-circle-outlined:before {
  content: "\ea77";
}

.antd-close-circle-filled-red-supplement:before {
  content: "\ea78";
}

.antd-close-circle-filled-white-supplement:before {
  content: "\ea79";
}

.antd-close-circle-filled:before {
  content: "\ea7a";
}

.antd-close-circle-outlined:before {
  content: "\ea7b";
}

.antd-close-circle-red-supplement:before {
  content: "\ea7c";
}

.antd-close-circle-white-supplement:before {
  content: "\ea7d";
}

.antd-close-outlined:before {
  content: "\ea7e";
}

.antd-close-square-filled:before {
  content: "\ea7f";
}

.antd-close-square-outlined:before {
  content: "\ea80";
}

.antd-cloud-download-outlined:before {
  content: "\ea81";
}

.antd-cloud-filled:before {
  content: "\ea82";
}

.antd-cloud-outlined:before {
  content: "\ea83";
}

.antd-cloud-server-outlined:before {
  content: "\ea84";
}

.antd-cloud-sync-outlined:before {
  content: "\ea85";
}

.antd-cloud-upload-outlined:before {
  content: "\ea86";
}

.antd-cluster-outlined:before {
  content: "\ea87";
}

.antd-code-filled:before {
  content: "\ea88";
}

.antd-code-outlined:before {
  content: "\ea89";
}

.antd-code-sandbox-circle-filled:before {
  content: "\ea8a";
}

.antd-code-sandbox-outlined:before {
  content: "\ea8b";
}

.antd-code-sandbox-square-filled:before {
  content: "\ea8c";
}

.antd-codepen-circle-filled:before {
  content: "\ea8d";
}

.antd-codepen-circle-outlined:before {
  content: "\ea8e";
}

.antd-codepen-outlined:before {
  content: "\ea8f";
}

.antd-codepen-square-filled:before {
  content: "\ea90";
}

.antd-coffee-outlined:before {
  content: "\ea91";
}

.antd-column-height-outlined:before {
  content: "\ea92";
}

.antd-column-width-outlined:before {
  content: "\ea93";
}

.antd-comment-outlined:before {
  content: "\ea94";
}

.antd-compass-filled:before {
  content: "\ea95";
}

.antd-compass-outlined:before {
  content: "\ea96";
}

.antd-compress-outlined:before {
  content: "\ea97";
}

.antd-console-sql-outlined:before {
  content: "\ea98";
}

.antd-contacts-filled:before {
  content: "\ea99";
}

.antd-contacts-outlined:before {
  content: "\ea9a";
}

.antd-container-filled:before {
  content: "\ea9b";
}

.antd-container-outlined:before {
  content: "\ea9c";
}

.antd-control-filled:before {
  content: "\ea9d";
}

.antd-control-outlined:before {
  content: "\ea9e";
}

.antd-copy-filled:before {
  content: "\ea9f";
}

.antd-copy-outlined:before {
  content: "\eaa0";
}

.antd-copyright-circle-filled:before {
  content: "\eaa1";
}

.antd-copyright-circle-outlined:before {
  content: "\eaa2";
}

.antd-copyright-outlined:before {
  content: "\eaa3";
}

.antd-credit-card-filled:before {
  content: "\eaa4";
}

.antd-credit-card-outlined:before {
  content: "\eaa5";
}

.antd-crown-filled:before {
  content: "\eaa6";
}

.antd-crown-outlined:before {
  content: "\eaa7";
}

.antd-customer-service-filled:before {
  content: "\eaa8";
}

.antd-customer-service-outlined:before {
  content: "\eaa9";
}

.antd-dash-outlined:before {
  content: "\eaaa";
}

.antd-dashboard-filled:before {
  content: "\eaab";
}

.antd-dashboard-outlined:before {
  content: "\eaac";
}

.antd-database-filled:before {
  content: "\eaad";
}

.antd-database-outlined:before {
  content: "\eaae";
}

.antd-delete-column-outlined:before {
  content: "\eaaf";
}

.antd-delete-filled:before {
  content: "\eab0";
}

.antd-delete-outlined:before {
  content: "\eab1";
}

.antd-delete-row-outlined:before {
  content: "\eab2";
}

.antd-delivered-procedure-outlined:before {
  content: "\eab3";
}

.antd-deployment-unit-outlined:before {
  content: "\eab4";
}

.antd-desktop-outlined:before {
  content: "\eab5";
}

.antd-diff-filled:before {
  content: "\eab6";
}

.antd-diff-outlined:before {
  content: "\eab7";
}

.antd-dingding-outlined:before {
  content: "\eab8";
}

.antd-dingtalk-circle-filled:before {
  content: "\eab9";
}

.antd-dingtalk-outlined:before {
  content: "\eaba";
}

.antd-dingtalk-square-filled:before {
  content: "\eabb";
}

.antd-disconnect-outlined:before {
  content: "\eabc";
}

.antd-dislike-filled:before {
  content: "\eabd";
}

.antd-dislike-outlined:before {
  content: "\eabe";
}

.antd-dollar-circle-filled:before {
  content: "\eabf";
}

.antd-dollar-circle-outlined:before {
  content: "\eac0";
}

.antd-dollar-outlined:before {
  content: "\eac1";
}

.antd-dot-chart-outlined:before {
  content: "\eac2";
}

.antd-double-down-supplement:before {
  content: "\eac3";
}

.antd-double-left-outlined:before {
  content: "\eac4";
}

.antd-double-right-outlined:before {
  content: "\eac5";
}

.antd-double-up-supplement:before {
  content: "\eac6";
}

.antd-down-circle-filled:before {
  content: "\eac7";
}

.antd-down-circle-outlined:before {
  content: "\eac8";
}

.antd-down-outlined:before {
  content: "\eac9";
}

.antd-down-square-filled:before {
  content: "\eaca";
}

.antd-down-square-outlined:before {
  content: "\eacb";
}

.antd-download-outlined:before {
  content: "\eacc";
}

.antd-drag-outlined:before {
  content: "\eacd";
}

.antd-dribbble-circle-filled:before {
  content: "\eace";
}

.antd-dribbble-outlined:before {
  content: "\eacf";
}

.antd-dribbble-square-filled:before {
  content: "\ead0";
}

.antd-dribbble-square-outlined:before {
  content: "\ead1";
}

.antd-dropbox-circle-filled:before {
  content: "\ead2";
}

.antd-dropbox-outlined:before {
  content: "\ead3";
}

.antd-dropbox-square-filled:before {
  content: "\ead4";
}

.antd-edit-filled:before {
  content: "\ead5";
}

.antd-edit-outlined:before {
  content: "\ead6";
}

.antd-ellipsis-outlined:before {
  content: "\ead7";
}

.antd-enter-outlined:before {
  content: "\ead8";
}

.antd-environment-filled:before {
  content: "\ead9";
}

.antd-environment-outlined:before {
  content: "\eada";
}

.antd-euro-circle-filled:before {
  content: "\eadb";
}

.antd-euro-circle-outlined:before {
  content: "\eadc";
}

.antd-euro-outlined:before {
  content: "\eadd";
}

.antd-exception-outlined:before {
  content: "\eade";
}

.antd-exclamation-circle-filled:before {
  content: "\eadf";
}

.antd-exclamation-circle-outlined:before {
  content: "\eae0";
}

.antd-exclamation-outlined:before {
  content: "\eae1";
}

.antd-expand-alt-outlined:before {
  content: "\eae2";
}

.antd-expand-outlined:before {
  content: "\eae3";
}

.antd-experiment-filled:before {
  content: "\eae4";
}

.antd-experiment-outlined:before {
  content: "\eae5";
}

.antd-export-outlined:before {
  content: "\eae6";
}

.antd-external-link-supplement:before {
  content: "\eae7";
}

.antd-eye-filled:before {
  content: "\eae8";
}

.antd-eye-invisible-filled:before {
  content: "\eae9";
}

.antd-eye-invisible-outlined:before {
  content: "\eaea";
}

.antd-eye-outlined:before {
  content: "\eaeb";
}

.antd-facebook-filled:before {
  content: "\eaec";
}

.antd-facebook-outlined:before {
  content: "\eaed";
}

.antd-fall-outlined:before {
  content: "\eaee";
}

.antd-fast-backward-filled:before {
  content: "\eaef";
}

.antd-fast-backward-outlined:before {
  content: "\eaf0";
}

.antd-fast-forward-filled:before {
  content: "\eaf1";
}

.antd-fast-forward-outlined:before {
  content: "\eaf2";
}

.antd-field-binary-outlined:before {
  content: "\eaf3";
}

.antd-field-number-outlined:before {
  content: "\eaf4";
}

.antd-field-string-outlined:before {
  content: "\eaf5";
}

.antd-field-time-outlined:before {
  content: "\eaf6";
}

.antd-file-add-filled:before {
  content: "\eaf7";
}

.antd-file-add-outlined:before {
  content: "\eaf8";
}

.antd-file-done-outlined:before {
  content: "\eaf9";
}

.antd-file-excel-filled:before {
  content: "\eafa";
}

.antd-file-excel-outlined:before {
  content: "\eafb";
}

.antd-file-exclamation-filled:before {
  content: "\eafc";
}

.antd-file-exclamation-outlined:before {
  content: "\eafd";
}

.antd-file-filled:before {
  content: "\eafe";
}

.antd-file-gif-outlined:before {
  content: "\eaff";
}

.antd-file-image-filled:before {
  content: "\eb00";
}

.antd-file-image-outlined:before {
  content: "\eb01";
}

.antd-file-jpg-outlined:before {
  content: "\eb02";
}

.antd-file-markdown-filled:before {
  content: "\eb03";
}

.antd-file-markdown-outlined:before {
  content: "\eb04";
}

.antd-file-outlined:before {
  content: "\eb05";
}

.antd-file-pdf-filled:before {
  content: "\eb06";
}

.antd-file-pdf-outlined:before {
  content: "\eb07";
}

.antd-file-ppt-filled:before {
  content: "\eb08";
}

.antd-file-ppt-outlined:before {
  content: "\eb09";
}

.antd-file-protect-outlined:before {
  content: "\eb0a";
}

.antd-file-search-outlined:before {
  content: "\eb0b";
}

.antd-file-sync-outlined:before {
  content: "\eb0c";
}

.antd-file-text-filled:before {
  content: "\eb0d";
}

.antd-file-text-grey-supplement:before {
  content: "\eb0e";
}

.antd-file-text-outlined:before {
  content: "\eb0f";
}

.antd-file-unknown-filled:before {
  content: "\eb10";
}

.antd-file-unknown-outlined:before {
  content: "\eb11";
}

.antd-file-word-filled:before {
  content: "\eb12";
}

.antd-file-word-outlined:before {
  content: "\eb13";
}

.antd-file-zip-filled:before {
  content: "\eb14";
}

.antd-file-zip-outlined:before {
  content: "\eb15";
}

.antd-filter-filled:before {
  content: "\eb16";
}

.antd-filter-outlined:before {
  content: "\eb17";
}

.antd-fire-filled:before {
  content: "\eb18";
}

.antd-fire-outlined:before {
  content: "\eb19";
}

.antd-flag-filled:before {
  content: "\eb1a";
}

.antd-flag-outlined:before {
  content: "\eb1b";
}

.antd-folder-add-filled:before {
  content: "\eb1c";
}

.antd-folder-add-outlined:before {
  content: "\eb1d";
}

.antd-folder-filled:before {
  content: "\eb1e";
}

.antd-folder-open-filled:before {
  content: "\eb1f";
}

.antd-folder-open-outlined:before {
  content: "\eb20";
}

.antd-folder-outlined:before {
  content: "\eb21";
}

.antd-folder-view-outlined:before {
  content: "\eb22";
}

.antd-font-colors-outlined:before {
  content: "\eb23";
}

.antd-font-size-outlined:before {
  content: "\eb24";
}

.antd-fork-outlined:before {
  content: "\eb25";
}

.antd-form-outlined:before {
  content: "\eb26";
}

.antd-format-painter-filled:before {
  content: "\eb27";
}

.antd-format-painter-outlined:before {
  content: "\eb28";
}

.antd-forward-filled:before {
  content: "\eb29";
}

.antd-forward-outlined:before {
  content: "\eb2a";
}

.antd-frown-filled:before {
  content: "\eb2b";
}

.antd-frown-outlined:before {
  content: "\eb2c";
}

.antd-fullscreen-exit-outlined:before {
  content: "\eb2d";
}

.antd-fullscreen-outlined:before {
  content: "\eb2e";
}

.antd-function-outlined:before {
  content: "\eb2f";
}

.antd-fund-filled:before {
  content: "\eb30";
}

.antd-fund-outlined:before {
  content: "\eb31";
}

.antd-fund-projection-screen-outlined:before {
  content: "\eb32";
}

.antd-fund-view-outlined:before {
  content: "\eb33";
}

.antd-funnel-plot-filled:before {
  content: "\eb34";
}

.antd-funnel-plot-outlined:before {
  content: "\eb35";
}

.antd-gateway-outlined:before {
  content: "\eb36";
}

.antd-gif-outlined:before {
  content: "\eb37";
}

.antd-gift-filled:before {
  content: "\eb38";
}

.antd-gift-outlined:before {
  content: "\eb39";
}

.antd-github-filled:before {
  content: "\eb3a";
}

.antd-github-outlined:before {
  content: "\eb3b";
}

.antd-gitlab-filled:before {
  content: "\eb3c";
}

.antd-gitlab-outlined:before {
  content: "\eb3d";
}

.antd-global-outlined:before {
  content: "\eb3e";
}

.antd-gold-filled:before {
  content: "\eb3f";
}

.antd-gold-outlined:before {
  content: "\eb40";
}

.antd-golden-filled:before {
  content: "\eb41";
}

.antd-google-circle-filled:before {
  content: "\eb42";
}

.antd-google-outlined:before {
  content: "\eb43";
}

.antd-google-plus-circle-filled:before {
  content: "\eb44";
}

.antd-google-plus-outlined:before {
  content: "\eb45";
}

.antd-google-plus-square-filled:before {
  content: "\eb46";
}

.antd-google-square-filled:before {
  content: "\eb47";
}

.antd-group-outlined:before {
  content: "\eb48";
}

.antd-hdd-filled:before {
  content: "\eb49";
}

.antd-hdd-outlined:before {
  content: "\eb4a";
}

.antd-heart-filled:before {
  content: "\eb4b";
}

.antd-heart-outlined:before {
  content: "\eb4c";
}

.antd-heat-map-outlined:before {
  content: "\eb4d";
}

.antd-highlight-filled:before {
  content: "\eb4e";
}

.antd-highlight-outlined:before {
  content: "\eb4f";
}

.antd-history-outlined:before {
  content: "\eb50";
}

.antd-holder-outlined:before {
  content: "\eb51";
}

.antd-home-filled:before {
  content: "\eb52";
}

.antd-home-outlined:before {
  content: "\eb53";
}

.antd-hourglass-filled:before {
  content: "\eb54";
}

.antd-hourglass-outlined:before {
  content: "\eb55";
}

.antd-html5-filled:before {
  content: "\eb56";
}

.antd-html5-outlined:before {
  content: "\eb57";
}

.antd-idcard-filled:before {
  content: "\eb58";
}

.antd-idcard-outlined:before {
  content: "\eb59";
}

.antd-ie-circle-filled:before {
  content: "\eb5a";
}

.antd-ie-outlined:before {
  content: "\eb5b";
}

.antd-ie-square-filled:before {
  content: "\eb5c";
}

.antd-import-outlined:before {
  content: "\eb5d";
}

.antd-inbox-outlined:before {
  content: "\eb5e";
}

.antd-info-circle-filled-grey-supplement:before {
  content: "\eb5f";
}

.antd-info-circle-filled-white-supplement:before {
  content: "\eb60";
}

.antd-info-circle-filled:before {
  content: "\eb61";
}

.antd-info-circle-grey-supplement:before {
  content: "\eb62";
}

.antd-info-circle-outlined:before {
  content: "\eb63";
}

.antd-info-circle-white-supplement:before {
  content: "\eb64";
}

.antd-info-outlined:before {
  content: "\eb65";
}

.antd-insert-row-above-outlined:before {
  content: "\eb66";
}

.antd-insert-row-below-outlined:before {
  content: "\eb67";
}

.antd-insert-row-left-outlined:before {
  content: "\eb68";
}

.antd-insert-row-right-outlined:before {
  content: "\eb69";
}

.antd-instagram-filled:before {
  content: "\eb6a";
}

.antd-instagram-outlined:before {
  content: "\eb6b";
}

.antd-insurance-filled:before {
  content: "\eb6c";
}

.antd-insurance-outlined:before {
  content: "\eb6d";
}

.antd-interaction-filled:before {
  content: "\eb6e";
}

.antd-interaction-outlined:before {
  content: "\eb6f";
}

.antd-issues-close-outlined:before {
  content: "\eb70";
}

.antd-italic-outlined:before {
  content: "\eb71";
}

.antd-key-outlined:before {
  content: "\eb72";
}

.antd-laptop-outlined:before {
  content: "\eb73";
}

.antd-layout-filled:before {
  content: "\eb74";
}

.antd-layout-outlined:before {
  content: "\eb75";
}

.antd-left-circle-filled:before {
  content: "\eb76";
}

.antd-left-circle-outlined:before {
  content: "\eb77";
}

.antd-left-outlined:before {
  content: "\eb78";
}

.antd-left-square-filled:before {
  content: "\eb79";
}

.antd-left-square-outlined:before {
  content: "\eb7a";
}

.antd-like-filled:before {
  content: "\eb7b";
}

.antd-like-outlined:before {
  content: "\eb7c";
}

.antd-line-chart-outlined:before {
  content: "\eb7d";
}

.antd-line-height-outlined:before {
  content: "\eb7e";
}

.antd-line-outlined:before {
  content: "\eb7f";
}

.antd-link-outlined:before {
  content: "\eb80";
}

.antd-linkedin-filled:before {
  content: "\eb81";
}

.antd-linkedin-outlined:before {
  content: "\eb82";
}

.antd-loading-3quarters-outlined:before {
  content: "\eb83";
}

.antd-loading-outlined:before {
  content: "\eb84";
}

.antd-lock-filled:before {
  content: "\eb85";
}

.antd-lock-outlined:before {
  content: "\eb86";
}

.antd-login-outlined:before {
  content: "\eb87";
}

.antd-logout-outlined:before {
  content: "\eb88";
}

.antd-logout-white-supplement:before {
  content: "\eb89";
}

.antd-mac-command-filled:before {
  content: "\eb8a";
}

.antd-mac-command-outlined:before {
  content: "\eb8b";
}

.antd-mail-filled:before {
  content: "\eb8c";
}

.antd-mail-outlined:before {
  content: "\eb8d";
}

.antd-man-outlined:before {
  content: "\eb8e";
}

.antd-medicine-box-filled:before {
  content: "\eb8f";
}

.antd-medicine-box-outlined:before {
  content: "\eb90";
}

.antd-medium-circle-filled:before {
  content: "\eb91";
}

.antd-medium-outlined:before {
  content: "\eb92";
}

.antd-medium-square-filled:before {
  content: "\eb93";
}

.antd-medium-workmark-outlined:before {
  content: "\eb94";
}

.antd-meh-filled:before {
  content: "\eb95";
}

.antd-meh-outlined:before {
  content: "\eb96";
}

.antd-menu-fold-outlined:before {
  content: "\eb97";
}

.antd-menu-outlined:before {
  content: "\eb98";
}

.antd-menu-unfold-outlined:before {
  content: "\eb99";
}

.antd-merge-cells-outlined:before {
  content: "\eb9a";
}

.antd-message-filled:before {
  content: "\eb9b";
}

.antd-message-outlined:before {
  content: "\eb9c";
}

.antd-minus-circle-filled:before {
  content: "\eb9d";
}

.antd-minus-circle-outlined:before {
  content: "\eb9e";
}

.antd-minus-outlined:before {
  content: "\eb9f";
}

.antd-minus-square-filled:before {
  content: "\eba0";
}

.antd-minus-square-outlined:before {
  content: "\eba1";
}

.antd-mobile-filled:before {
  content: "\eba2";
}

.antd-mobile-outlined:before {
  content: "\eba3";
}

.antd-money-collect-filled:before {
  content: "\eba4";
}

.antd-money-collect-outlined:before {
  content: "\eba5";
}

.antd-monitor-outlined:before {
  content: "\eba6";
}

.antd-more-outlined:before {
  content: "\eba7";
}

.antd-node-collapse-outlined:before {
  content: "\eba8";
}

.antd-node-expand-outlined:before {
  content: "\eba9";
}

.antd-node-index-outlined:before {
  content: "\ebaa";
}

.antd-notification-filled:before {
  content: "\ebab";
}

.antd-notification-outlined:before {
  content: "\ebac";
}

.antd-number-outlined:before {
  content: "\ebad";
}

.antd-one-to-one-outlined:before {
  content: "\ebae";
}

.antd-ordered-list-outlined:before {
  content: "\ebaf";
}

.antd-paper-clip-outlined:before {
  content: "\ebb0";
}

.antd-partition-outlined:before {
  content: "\ebb1";
}

.antd-pause-circle-filled:before {
  content: "\ebb2";
}

.antd-pause-circle-outlined:before {
  content: "\ebb3";
}

.antd-pause-outlined:before {
  content: "\ebb4";
}

.antd-pay-circle-filled:before {
  content: "\ebb5";
}

.antd-pay-circle-outlined:before {
  content: "\ebb6";
}

.antd-percentage-outlined:before {
  content: "\ebb7";
}

.antd-phone-filled:before {
  content: "\ebb8";
}

.antd-phone-outlined:before {
  content: "\ebb9";
}

.antd-pic-center-outlined:before {
  content: "\ebba";
}

.antd-pic-left-outlined:before {
  content: "\ebbb";
}

.antd-pic-right-outlined:before {
  content: "\ebbc";
}

.antd-picture-filled:before {
  content: "\ebbd";
}

.antd-picture-outlined:before {
  content: "\ebbe";
}

.antd-pie-chart-filled:before {
  content: "\ebbf";
}

.antd-pie-chart-outlined:before {
  content: "\ebc0";
}

.antd-play-circle-filled:before {
  content: "\ebc1";
}

.antd-play-circle-grey-supplement:before {
  content: "\ebc2";
}

.antd-play-circle-outlined:before {
  content: "\ebc3";
}

.antd-play-square-filled:before {
  content: "\ebc4";
}

.antd-play-square-grey-supplement:before {
  content: "\ebc5";
}

.antd-play-square-outlined:before {
  content: "\ebc6";
}

.antd-plus-circle-blue-supplement:before {
  content: "\ebc7";
}

.antd-plus-circle-filled-blue-supplement:before {
  content: "\ebc8";
}

.antd-plus-circle-filled-grey-supplement:before {
  content: "\ebc9";
}

.antd-plus-circle-filled:before {
  content: "\ebca";
}

.antd-plus-circle-grey-supplement:before {
  content: "\ebcb";
}

.antd-plus-circle-outlined:before {
  content: "\ebcc";
}

.antd-plus-outlined:before {
  content: "\ebcd";
}

.antd-plus-square-filled:before {
  content: "\ebce";
}

.antd-plus-square-outlined:before {
  content: "\ebcf";
}

.antd-plus-white-supplement:before {
  content: "\ebd0";
}

.antd-pound-circle-filled:before {
  content: "\ebd1";
}

.antd-pound-circle-outlined:before {
  content: "\ebd2";
}

.antd-pound-outlined:before {
  content: "\ebd3";
}

.antd-poweroff-outlined:before {
  content: "\ebd4";
}

.antd-printer-filled:before {
  content: "\ebd5";
}

.antd-printer-outlined:before {
  content: "\ebd6";
}

.antd-profile-filled:before {
  content: "\ebd7";
}

.antd-profile-outlined:before {
  content: "\ebd8";
}

.antd-project-filled:before {
  content: "\ebd9";
}

.antd-project-outlined:before {
  content: "\ebda";
}

.antd-property-safety-filled:before {
  content: "\ebdb";
}

.antd-property-safety-outlined:before {
  content: "\ebdc";
}

.antd-pull-request-outlined:before {
  content: "\ebdd";
}

.antd-pushpin-filled:before {
  content: "\ebde";
}

.antd-pushpin-outlined:before {
  content: "\ebdf";
}

.antd-qq-circle-filled:before {
  content: "\ebe0";
}

.antd-qq-outlined:before {
  content: "\ebe1";
}

.antd-qq-square-filled:before {
  content: "\ebe2";
}

.antd-qrcode-outlined:before {
  content: "\ebe3";
}

.antd-question-circle-filled:before {
  content: "\ebe4";
}

.antd-question-circle-outlined:before {
  content: "\ebe5";
}

.antd-question-outlined:before {
  content: "\ebe6";
}

.antd-radar-chart-outlined:before {
  content: "\ebe7";
}

.antd-radius-bottomleft-outlined:before {
  content: "\ebe8";
}

.antd-radius-bottomright-outlined:before {
  content: "\ebe9";
}

.antd-radius-setting-outlined:before {
  content: "\ebea";
}

.antd-radius-upleft-outlined:before {
  content: "\ebeb";
}

.antd-radius-upright-outlined:before {
  content: "\ebec";
}

.antd-read-filled:before {
  content: "\ebed";
}

.antd-read-outlined:before {
  content: "\ebee";
}

.antd-reconciliation-filled:before {
  content: "\ebef";
}

.antd-reconciliation-outlined:before {
  content: "\ebf0";
}

.antd-red-envelope-filled:before {
  content: "\ebf1";
}

.antd-red-envelope-outlined:before {
  content: "\ebf2";
}

.antd-reddit-circle-filled:before {
  content: "\ebf3";
}

.antd-reddit-outlined:before {
  content: "\ebf4";
}

.antd-reddit-square-filled:before {
  content: "\ebf5";
}

.antd-redo-outlined:before {
  content: "\ebf6";
}

.antd-reload-outlined:before {
  content: "\ebf7";
}

.antd-rest-filled:before {
  content: "\ebf8";
}

.antd-rest-outlined:before {
  content: "\ebf9";
}

.antd-retweet-outlined:before {
  content: "\ebfa";
}

.antd-right-circle-filled:before {
  content: "\ebfb";
}

.antd-right-circle-outlined:before {
  content: "\ebfc";
}

.antd-right-outlined:before {
  content: "\ebfd";
}

.antd-right-square-filled:before {
  content: "\ebfe";
}

.antd-right-square-outlined:before {
  content: "\ebff";
}

.antd-rise-outlined:before {
  content: "\ec00";
}

.antd-robot-filled:before {
  content: "\ec01";
}

.antd-robot-outlined:before {
  content: "\ec02";
}

.antd-rocket-filled:before {
  content: "\ec03";
}

.antd-rocket-outlined:before {
  content: "\ec04";
}

.antd-rollback-outlined:before {
  content: "\ec05";
}

.antd-rotate-left-outlined:before {
  content: "\ec06";
}

.antd-rotate-right-outlined:before {
  content: "\ec07";
}

.antd-safety-certificate-filled:before {
  content: "\ec08";
}

.antd-safety-certificate-outlined:before {
  content: "\ec09";
}

.antd-safety-outlined:before {
  content: "\ec0a";
}

.antd-save-filled:before {
  content: "\ec0b";
}

.antd-save-outlined:before {
  content: "\ec0c";
}

.antd-scan-outlined:before {
  content: "\ec0d";
}

.antd-schedule-filled:before {
  content: "\ec0e";
}

.antd-schedule-outlined:before {
  content: "\ec0f";
}

.antd-scissor-outlined:before {
  content: "\ec10";
}

.antd-search-outlined:before {
  content: "\ec11";
}

.antd-security-scan-filled:before {
  content: "\ec12";
}

.antd-security-scan-outlined:before {
  content: "\ec13";
}

.antd-select-outlined:before {
  content: "\ec14";
}

.antd-send-outlined:before {
  content: "\ec15";
}

.antd-setting-filled:before {
  content: "\ec16";
}

.antd-setting-outlined:before {
  content: "\ec17";
}

.antd-shake-outlined:before {
  content: "\ec18";
}

.antd-share-alt-outlined:before {
  content: "\ec19";
}

.antd-shop-filled:before {
  content: "\ec1a";
}

.antd-shop-outlined:before {
  content: "\ec1b";
}

.antd-shopping-cart-outlined:before {
  content: "\ec1c";
}

.antd-shopping-filled:before {
  content: "\ec1d";
}

.antd-shopping-outlined:before {
  content: "\ec1e";
}

.antd-shrink-outlined:before {
  content: "\ec1f";
}

.antd-signal-filled:before {
  content: "\ec20";
}

.antd-sisternode-outlined:before {
  content: "\ec21";
}

.antd-sketch-circle-filled:before {
  content: "\ec22";
}

.antd-sketch-outlined:before {
  content: "\ec23";
}

.antd-sketch-square-filled:before {
  content: "\ec24";
}

.antd-skin-filled:before {
  content: "\ec25";
}

.antd-skin-outlined:before {
  content: "\ec26";
}

.antd-skype-filled:before {
  content: "\ec27";
}

.antd-skype-outlined:before {
  content: "\ec28";
}

.antd-slack-circle-filled:before {
  content: "\ec29";
}

.antd-slack-outlined:before {
  content: "\ec2a";
}

.antd-slack-square-filled:before {
  content: "\ec2b";
}

.antd-slack-square-outlined:before {
  content: "\ec2c";
}

.antd-sliders-filled:before {
  content: "\ec2d";
}

.antd-sliders-outlined:before {
  content: "\ec2e";
}

.antd-small-dash-outlined:before {
  content: "\ec2f";
}

.antd-smile-filled:before {
  content: "\ec30";
}

.antd-smile-outlined:before {
  content: "\ec31";
}

.antd-snippets-filled:before {
  content: "\ec32";
}

.antd-snippets-outlined:before {
  content: "\ec33";
}

.antd-solution-outlined:before {
  content: "\ec34";
}

.antd-sort-asc-supplement:before {
  content: "\ec35";
}

.antd-sort-ascending-outlined:before {
  content: "\ec36";
}

.antd-sort-desc-supplement:before {
  content: "\ec37";
}

.antd-sort-descending-outlined:before {
  content: "\ec38";
}

.antd-sound-filled:before {
  content: "\ec39";
}

.antd-sound-outlined:before {
  content: "\ec3a";
}

.antd-split-cells-outlined:before {
  content: "\ec3b";
}

.antd-star-filled:before {
  content: "\ec3c";
}

.antd-star-outlined:before {
  content: "\ec3d";
}

.antd-step-backward-filled:before {
  content: "\ec3e";
}

.antd-step-backward-outlined:before {
  content: "\ec3f";
}

.antd-step-forward-filled:before {
  content: "\ec40";
}

.antd-step-forward-outlined:before {
  content: "\ec41";
}

.antd-stock-outlined:before {
  content: "\ec42";
}

.antd-stop-filled:before {
  content: "\ec43";
}

.antd-stop-outlined:before {
  content: "\ec44";
}

.antd-strikethrough-outlined:before {
  content: "\ec45";
}

.antd-subnode-outlined:before {
  content: "\ec46";
}

.antd-swap-left-outlined:before {
  content: "\ec47";
}

.antd-swap-outlined:before {
  content: "\ec48";
}

.antd-swap-right-outlined:before {
  content: "\ec49";
}

.antd-switcher-filled:before {
  content: "\ec4a";
}

.antd-switcher-outlined:before {
  content: "\ec4b";
}

.antd-sync-outlined:before {
  content: "\ec4c";
}

.antd-table-outlined:before {
  content: "\ec4d";
}

.antd-tablet-filled:before {
  content: "\ec4e";
}

.antd-tablet-outlined:before {
  content: "\ec4f";
}

.antd-tag-filled:before {
  content: "\ec50";
}

.antd-tag-outlined:before {
  content: "\ec51";
}

.antd-tags-filled:before {
  content: "\ec52";
}

.antd-tags-outlined:before {
  content: "\ec53";
}

.antd-taobao-circle-filled:before {
  content: "\ec54";
}

.antd-taobao-circle-outlined:before {
  content: "\ec55";
}

.antd-taobao-outlined:before {
  content: "\ec56";
}

.antd-taobao-square-filled:before {
  content: "\ec57";
}

.antd-team-outlined:before {
  content: "\ec58";
}

.antd-thunderbolt-filled:before {
  content: "\ec59";
}

.antd-thunderbolt-outlined:before {
  content: "\ec5a";
}

.antd-to-top-outlined:before {
  content: "\ec5b";
}

.antd-tool-filled:before {
  content: "\ec5c";
}

.antd-tool-outlined:before {
  content: "\ec5d";
}

.antd-trademark-circle-filled:before {
  content: "\ec5e";
}

.antd-trademark-circle-outlined:before {
  content: "\ec5f";
}

.antd-trademark-outlined:before {
  content: "\ec60";
}

.antd-transaction-outlined:before {
  content: "\ec61";
}

.antd-translation-outlined:before {
  content: "\ec62";
}

.antd-trophy-filled:before {
  content: "\ec63";
}

.antd-trophy-outlined:before {
  content: "\ec64";
}

.antd-twitter-circle-filled:before {
  content: "\ec65";
}

.antd-twitter-outlined:before {
  content: "\ec66";
}

.antd-twitter-square-filled:before {
  content: "\ec67";
}

.antd-underline-outlined:before {
  content: "\ec68";
}

.antd-undo-outlined:before {
  content: "\ec69";
}

.antd-ungroup-outlined:before {
  content: "\ec6a";
}

.antd-unlock-filled:before {
  content: "\ec6b";
}

.antd-unlock-outlined:before {
  content: "\ec6c";
}

.antd-unordered-list-outlined:before {
  content: "\ec6d";
}

.antd-up-circle-filled:before {
  content: "\ec6e";
}

.antd-up-circle-outlined:before {
  content: "\ec6f";
}

.antd-up-outlined:before {
  content: "\ec70";
}

.antd-up-square-filled:before {
  content: "\ec71";
}

.antd-up-square-outlined:before {
  content: "\ec72";
}

.antd-upload-outlined:before {
  content: "\ec73";
}

.antd-usb-filled:before {
  content: "\ec74";
}

.antd-usb-outlined:before {
  content: "\ec75";
}

.antd-user-add-outlined:before {
  content: "\ec76";
}

.antd-user-delete-outlined:before {
  content: "\ec77";
}

.antd-user-outlined:before {
  content: "\ec78";
}

.antd-user-switch-outlined:before {
  content: "\ec79";
}

.antd-usergroup-add-outlined:before {
  content: "\ec7a";
}

.antd-usergroup-delete-outlined:before {
  content: "\ec7b";
}

.antd-verified-outlined:before {
  content: "\ec7c";
}

.antd-vertical-align-bottom-outlined:before {
  content: "\ec7d";
}

.antd-vertical-align-middle-outlined:before {
  content: "\ec7e";
}

.antd-vertical-align-top-outlined:before {
  content: "\ec7f";
}

.antd-vertical-left-outlined:before {
  content: "\ec80";
}

.antd-vertical-right-outlined:before {
  content: "\ec81";
}

.antd-video-camera-add-outlined:before {
  content: "\ec82";
}

.antd-video-camera-filled:before {
  content: "\ec83";
}

.antd-video-camera-outlined:before {
  content: "\ec84";
}

.antd-wallet-filled:before {
  content: "\ec85";
}

.antd-wallet-outlined:before {
  content: "\ec86";
}

.antd-warning-filled:before {
  content: "\ec87";
}

.antd-warning-outlined:before {
  content: "\ec88";
}

.antd-wechat-filled:before {
  content: "\ec89";
}

.antd-wechat-outlined:before {
  content: "\ec8a";
}

.antd-weibo-circle-filled:before {
  content: "\ec8b";
}

.antd-weibo-circle-outlined:before {
  content: "\ec8c";
}

.antd-weibo-outlined:before {
  content: "\ec8d";
}

.antd-weibo-square-filled:before {
  content: "\ec8e";
}

.antd-weibo-square-outlined:before {
  content: "\ec8f";
}

.antd-whats-app-outlined:before {
  content: "\ec90";
}

.antd-wifi-outlined:before {
  content: "\ec91";
}

.antd-windows-filled:before {
  content: "\ec92";
}

.antd-windows-outlined:before {
  content: "\ec93";
}

.antd-woman-outlined:before {
  content: "\ec94";
}

.antd-yahoo-filled:before {
  content: "\ec95";
}

.antd-yahoo-outlined:before {
  content: "\ec96";
}

.antd-youtube-filled:before {
  content: "\ec97";
}

.antd-youtube-outlined:before {
  content: "\ec98";
}

.antd-yuque-filled:before {
  content: "\ec99";
}

.antd-yuque-outlined:before {
  content: "\ec9a";
}

.antd-zhihu-circle-filled:before {
  content: "\ec9b";
}

.antd-zhihu-outlined:before {
  content: "\ec9c";
}

.antd-zhihu-square-filled:before {
  content: "\ec9d";
}

.antd-zoom-in-outlined:before {
  content: "\ec9e";
}

.antd-zoom-out-outlined:before {
  content: "\ec9f";
}

$antd-account-book-filled: "\ea01";
$antd-account-book-outlined: "\ea02";
$antd-aim-outlined: "\ea03";
$antd-alert-filled: "\ea04";
$antd-alert-outlined: "\ea05";
$antd-alibaba-outlined: "\ea06";
$antd-align-center-outlined: "\ea07";
$antd-align-left-outlined: "\ea08";
$antd-align-right-outlined: "\ea09";
$antd-alipay-circle-filled: "\ea0a";
$antd-alipay-circle-outlined: "\ea0b";
$antd-alipay-outlined: "\ea0c";
$antd-alipay-square-filled: "\ea0d";
$antd-aliwangwang-filled: "\ea0e";
$antd-aliwangwang-outlined: "\ea0f";
$antd-aliyun-outlined: "\ea10";
$antd-amazon-circle-filled: "\ea11";
$antd-amazon-outlined: "\ea12";
$antd-amazon-square-filled: "\ea13";
$antd-android-filled: "\ea14";
$antd-android-outlined: "\ea15";
$antd-ant-cloud-outlined: "\ea16";
$antd-ant-design-outlined: "\ea17";
$antd-apartment-outlined: "\ea18";
$antd-api-filled: "\ea19";
$antd-api-outlined: "\ea1a";
$antd-apple-filled: "\ea1b";
$antd-apple-outlined: "\ea1c";
$antd-appstore-add-outlined: "\ea1d";
$antd-appstore-filled: "\ea1e";
$antd-appstore-outlined: "\ea1f";
$antd-area-chart-outlined: "\ea20";
$antd-arrow-down-outlined: "\ea21";
$antd-arrow-left-outlined: "\ea22";
$antd-arrow-right-outlined: "\ea23";
$antd-arrow-up-outlined: "\ea24";
$antd-arrows-alt-outlined: "\ea25";
$antd-audio-filled: "\ea26";
$antd-audio-muted-outlined: "\ea27";
$antd-audio-outlined: "\ea28";
$antd-audit-outlined: "\ea29";
$antd-backward-filled: "\ea2a";
$antd-backward-outlined: "\ea2b";
$antd-bank-filled: "\ea2c";
$antd-bank-outlined: "\ea2d";
$antd-bar-chart-outlined: "\ea2e";
$antd-barcode-outlined: "\ea2f";
$antd-bars-outlined: "\ea30";
$antd-behance-circle-filled: "\ea31";
$antd-behance-outlined: "\ea32";
$antd-behance-square-filled: "\ea33";
$antd-behance-square-outlined: "\ea34";
$antd-bell-filled: "\ea35";
$antd-bell-outlined: "\ea36";
$antd-bg-colors-outlined: "\ea37";
$antd-block-outlined: "\ea38";
$antd-bold-outlined: "\ea39";
$antd-book-filled: "\ea3a";
$antd-book-outlined: "\ea3b";
$antd-border-bottom-outlined: "\ea3c";
$antd-border-horizontal-outlined: "\ea3d";
$antd-border-inner-outlined: "\ea3e";
$antd-border-left-outlined: "\ea3f";
$antd-border-outer-outlined: "\ea40";
$antd-border-outlined: "\ea41";
$antd-border-right-outlined: "\ea42";
$antd-border-top-outlined: "\ea43";
$antd-border-verticle-outlined: "\ea44";
$antd-borderless-table-outlined: "\ea45";
$antd-box-plot-filled: "\ea46";
$antd-box-plot-outlined: "\ea47";
$antd-branches-outlined: "\ea48";
$antd-bug-filled: "\ea49";
$antd-bug-outlined: "\ea4a";
$antd-build-filled: "\ea4b";
$antd-build-outlined: "\ea4c";
$antd-bulb-filled: "\ea4d";
$antd-bulb-outlined: "\ea4e";
$antd-calculator-filled: "\ea4f";
$antd-calculator-outlined: "\ea50";
$antd-calendar-filled: "\ea51";
$antd-calendar-outlined: "\ea52";
$antd-camera-filled: "\ea53";
$antd-camera-outlined: "\ea54";
$antd-car-blue-supplement: "\ea55";
$antd-car-filled-blue-supplement: "\ea56";
$antd-car-filled: "\ea57";
$antd-car-outlined: "\ea58";
$antd-caret-down-filled: "\ea59";
$antd-caret-down-outlined: "\ea5a";
$antd-caret-left-filled: "\ea5b";
$antd-caret-left-outlined: "\ea5c";
$antd-caret-right-filled: "\ea5d";
$antd-caret-right-outlined: "\ea5e";
$antd-caret-up-filled: "\ea5f";
$antd-caret-up-outlined: "\ea60";
$antd-carry-out-filled: "\ea61";
$antd-carry-out-outlined: "\ea62";
$antd-check-blue-supplement: "\ea63";
$antd-check-circle-filled-green-supplement: "\ea64";
$antd-check-circle-filled-white-supplement: "\ea65";
$antd-check-circle-filled: "\ea66";
$antd-check-circle-green-supplement: "\ea67";
$antd-check-circle-outlined: "\ea68";
$antd-check-circle-white-supplement: "\ea69";
$antd-check-outlined: "\ea6a";
$antd-check-square-blue-supplement: "\ea6b";
$antd-check-square-filled: "\ea6c";
$antd-check-square-outlined: "\ea6d";
$antd-chrome-filled: "\ea6e";
$antd-chrome-outlined: "\ea6f";
$antd-ci-circle-filled: "\ea70";
$antd-ci-circle-outlined: "\ea71";
$antd-ci-outlined: "\ea72";
$antd-circle-filled-black-supplement: "\ea73";
$antd-circle-grey-supplement: "\ea74";
$antd-clear-outlined: "\ea75";
$antd-clock-circle-filled: "\ea76";
$antd-clock-circle-outlined: "\ea77";
$antd-close-circle-filled-red-supplement: "\ea78";
$antd-close-circle-filled-white-supplement: "\ea79";
$antd-close-circle-filled: "\ea7a";
$antd-close-circle-outlined: "\ea7b";
$antd-close-circle-red-supplement: "\ea7c";
$antd-close-circle-white-supplement: "\ea7d";
$antd-close-outlined: "\ea7e";
$antd-close-square-filled: "\ea7f";
$antd-close-square-outlined: "\ea80";
$antd-cloud-download-outlined: "\ea81";
$antd-cloud-filled: "\ea82";
$antd-cloud-outlined: "\ea83";
$antd-cloud-server-outlined: "\ea84";
$antd-cloud-sync-outlined: "\ea85";
$antd-cloud-upload-outlined: "\ea86";
$antd-cluster-outlined: "\ea87";
$antd-code-filled: "\ea88";
$antd-code-outlined: "\ea89";
$antd-code-sandbox-circle-filled: "\ea8a";
$antd-code-sandbox-outlined: "\ea8b";
$antd-code-sandbox-square-filled: "\ea8c";
$antd-codepen-circle-filled: "\ea8d";
$antd-codepen-circle-outlined: "\ea8e";
$antd-codepen-outlined: "\ea8f";
$antd-codepen-square-filled: "\ea90";
$antd-coffee-outlined: "\ea91";
$antd-column-height-outlined: "\ea92";
$antd-column-width-outlined: "\ea93";
$antd-comment-outlined: "\ea94";
$antd-compass-filled: "\ea95";
$antd-compass-outlined: "\ea96";
$antd-compress-outlined: "\ea97";
$antd-console-sql-outlined: "\ea98";
$antd-contacts-filled: "\ea99";
$antd-contacts-outlined: "\ea9a";
$antd-container-filled: "\ea9b";
$antd-container-outlined: "\ea9c";
$antd-control-filled: "\ea9d";
$antd-control-outlined: "\ea9e";
$antd-copy-filled: "\ea9f";
$antd-copy-outlined: "\eaa0";
$antd-copyright-circle-filled: "\eaa1";
$antd-copyright-circle-outlined: "\eaa2";
$antd-copyright-outlined: "\eaa3";
$antd-credit-card-filled: "\eaa4";
$antd-credit-card-outlined: "\eaa5";
$antd-crown-filled: "\eaa6";
$antd-crown-outlined: "\eaa7";
$antd-customer-service-filled: "\eaa8";
$antd-customer-service-outlined: "\eaa9";
$antd-dash-outlined: "\eaaa";
$antd-dashboard-filled: "\eaab";
$antd-dashboard-outlined: "\eaac";
$antd-database-filled: "\eaad";
$antd-database-outlined: "\eaae";
$antd-delete-column-outlined: "\eaaf";
$antd-delete-filled: "\eab0";
$antd-delete-outlined: "\eab1";
$antd-delete-row-outlined: "\eab2";
$antd-delivered-procedure-outlined: "\eab3";
$antd-deployment-unit-outlined: "\eab4";
$antd-desktop-outlined: "\eab5";
$antd-diff-filled: "\eab6";
$antd-diff-outlined: "\eab7";
$antd-dingding-outlined: "\eab8";
$antd-dingtalk-circle-filled: "\eab9";
$antd-dingtalk-outlined: "\eaba";
$antd-dingtalk-square-filled: "\eabb";
$antd-disconnect-outlined: "\eabc";
$antd-dislike-filled: "\eabd";
$antd-dislike-outlined: "\eabe";
$antd-dollar-circle-filled: "\eabf";
$antd-dollar-circle-outlined: "\eac0";
$antd-dollar-outlined: "\eac1";
$antd-dot-chart-outlined: "\eac2";
$antd-double-down-supplement: "\eac3";
$antd-double-left-outlined: "\eac4";
$antd-double-right-outlined: "\eac5";
$antd-double-up-supplement: "\eac6";
$antd-down-circle-filled: "\eac7";
$antd-down-circle-outlined: "\eac8";
$antd-down-outlined: "\eac9";
$antd-down-square-filled: "\eaca";
$antd-down-square-outlined: "\eacb";
$antd-download-outlined: "\eacc";
$antd-drag-outlined: "\eacd";
$antd-dribbble-circle-filled: "\eace";
$antd-dribbble-outlined: "\eacf";
$antd-dribbble-square-filled: "\ead0";
$antd-dribbble-square-outlined: "\ead1";
$antd-dropbox-circle-filled: "\ead2";
$antd-dropbox-outlined: "\ead3";
$antd-dropbox-square-filled: "\ead4";
$antd-edit-filled: "\ead5";
$antd-edit-outlined: "\ead6";
$antd-ellipsis-outlined: "\ead7";
$antd-enter-outlined: "\ead8";
$antd-environment-filled: "\ead9";
$antd-environment-outlined: "\eada";
$antd-euro-circle-filled: "\eadb";
$antd-euro-circle-outlined: "\eadc";
$antd-euro-outlined: "\eadd";
$antd-exception-outlined: "\eade";
$antd-exclamation-circle-filled: "\eadf";
$antd-exclamation-circle-outlined: "\eae0";
$antd-exclamation-outlined: "\eae1";
$antd-expand-alt-outlined: "\eae2";
$antd-expand-outlined: "\eae3";
$antd-experiment-filled: "\eae4";
$antd-experiment-outlined: "\eae5";
$antd-export-outlined: "\eae6";
$antd-external-link-supplement: "\eae7";
$antd-eye-filled: "\eae8";
$antd-eye-invisible-filled: "\eae9";
$antd-eye-invisible-outlined: "\eaea";
$antd-eye-outlined: "\eaeb";
$antd-facebook-filled: "\eaec";
$antd-facebook-outlined: "\eaed";
$antd-fall-outlined: "\eaee";
$antd-fast-backward-filled: "\eaef";
$antd-fast-backward-outlined: "\eaf0";
$antd-fast-forward-filled: "\eaf1";
$antd-fast-forward-outlined: "\eaf2";
$antd-field-binary-outlined: "\eaf3";
$antd-field-number-outlined: "\eaf4";
$antd-field-string-outlined: "\eaf5";
$antd-field-time-outlined: "\eaf6";
$antd-file-add-filled: "\eaf7";
$antd-file-add-outlined: "\eaf8";
$antd-file-done-outlined: "\eaf9";
$antd-file-excel-filled: "\eafa";
$antd-file-excel-outlined: "\eafb";
$antd-file-exclamation-filled: "\eafc";
$antd-file-exclamation-outlined: "\eafd";
$antd-file-filled: "\eafe";
$antd-file-gif-outlined: "\eaff";
$antd-file-image-filled: "\eb00";
$antd-file-image-outlined: "\eb01";
$antd-file-jpg-outlined: "\eb02";
$antd-file-markdown-filled: "\eb03";
$antd-file-markdown-outlined: "\eb04";
$antd-file-outlined: "\eb05";
$antd-file-pdf-filled: "\eb06";
$antd-file-pdf-outlined: "\eb07";
$antd-file-ppt-filled: "\eb08";
$antd-file-ppt-outlined: "\eb09";
$antd-file-protect-outlined: "\eb0a";
$antd-file-search-outlined: "\eb0b";
$antd-file-sync-outlined: "\eb0c";
$antd-file-text-filled: "\eb0d";
$antd-file-text-grey-supplement: "\eb0e";
$antd-file-text-outlined: "\eb0f";
$antd-file-unknown-filled: "\eb10";
$antd-file-unknown-outlined: "\eb11";
$antd-file-word-filled: "\eb12";
$antd-file-word-outlined: "\eb13";
$antd-file-zip-filled: "\eb14";
$antd-file-zip-outlined: "\eb15";
$antd-filter-filled: "\eb16";
$antd-filter-outlined: "\eb17";
$antd-fire-filled: "\eb18";
$antd-fire-outlined: "\eb19";
$antd-flag-filled: "\eb1a";
$antd-flag-outlined: "\eb1b";
$antd-folder-add-filled: "\eb1c";
$antd-folder-add-outlined: "\eb1d";
$antd-folder-filled: "\eb1e";
$antd-folder-open-filled: "\eb1f";
$antd-folder-open-outlined: "\eb20";
$antd-folder-outlined: "\eb21";
$antd-folder-view-outlined: "\eb22";
$antd-font-colors-outlined: "\eb23";
$antd-font-size-outlined: "\eb24";
$antd-fork-outlined: "\eb25";
$antd-form-outlined: "\eb26";
$antd-format-painter-filled: "\eb27";
$antd-format-painter-outlined: "\eb28";
$antd-forward-filled: "\eb29";
$antd-forward-outlined: "\eb2a";
$antd-frown-filled: "\eb2b";
$antd-frown-outlined: "\eb2c";
$antd-fullscreen-exit-outlined: "\eb2d";
$antd-fullscreen-outlined: "\eb2e";
$antd-function-outlined: "\eb2f";
$antd-fund-filled: "\eb30";
$antd-fund-outlined: "\eb31";
$antd-fund-projection-screen-outlined: "\eb32";
$antd-fund-view-outlined: "\eb33";
$antd-funnel-plot-filled: "\eb34";
$antd-funnel-plot-outlined: "\eb35";
$antd-gateway-outlined: "\eb36";
$antd-gif-outlined: "\eb37";
$antd-gift-filled: "\eb38";
$antd-gift-outlined: "\eb39";
$antd-github-filled: "\eb3a";
$antd-github-outlined: "\eb3b";
$antd-gitlab-filled: "\eb3c";
$antd-gitlab-outlined: "\eb3d";
$antd-global-outlined: "\eb3e";
$antd-gold-filled: "\eb3f";
$antd-gold-outlined: "\eb40";
$antd-golden-filled: "\eb41";
$antd-google-circle-filled: "\eb42";
$antd-google-outlined: "\eb43";
$antd-google-plus-circle-filled: "\eb44";
$antd-google-plus-outlined: "\eb45";
$antd-google-plus-square-filled: "\eb46";
$antd-google-square-filled: "\eb47";
$antd-group-outlined: "\eb48";
$antd-hdd-filled: "\eb49";
$antd-hdd-outlined: "\eb4a";
$antd-heart-filled: "\eb4b";
$antd-heart-outlined: "\eb4c";
$antd-heat-map-outlined: "\eb4d";
$antd-highlight-filled: "\eb4e";
$antd-highlight-outlined: "\eb4f";
$antd-history-outlined: "\eb50";
$antd-holder-outlined: "\eb51";
$antd-home-filled: "\eb52";
$antd-home-outlined: "\eb53";
$antd-hourglass-filled: "\eb54";
$antd-hourglass-outlined: "\eb55";
$antd-html5-filled: "\eb56";
$antd-html5-outlined: "\eb57";
$antd-idcard-filled: "\eb58";
$antd-idcard-outlined: "\eb59";
$antd-ie-circle-filled: "\eb5a";
$antd-ie-outlined: "\eb5b";
$antd-ie-square-filled: "\eb5c";
$antd-import-outlined: "\eb5d";
$antd-inbox-outlined: "\eb5e";
$antd-info-circle-filled-grey-supplement: "\eb5f";
$antd-info-circle-filled-white-supplement: "\eb60";
$antd-info-circle-filled: "\eb61";
$antd-info-circle-grey-supplement: "\eb62";
$antd-info-circle-outlined: "\eb63";
$antd-info-circle-white-supplement: "\eb64";
$antd-info-outlined: "\eb65";
$antd-insert-row-above-outlined: "\eb66";
$antd-insert-row-below-outlined: "\eb67";
$antd-insert-row-left-outlined: "\eb68";
$antd-insert-row-right-outlined: "\eb69";
$antd-instagram-filled: "\eb6a";
$antd-instagram-outlined: "\eb6b";
$antd-insurance-filled: "\eb6c";
$antd-insurance-outlined: "\eb6d";
$antd-interaction-filled: "\eb6e";
$antd-interaction-outlined: "\eb6f";
$antd-issues-close-outlined: "\eb70";
$antd-italic-outlined: "\eb71";
$antd-key-outlined: "\eb72";
$antd-laptop-outlined: "\eb73";
$antd-layout-filled: "\eb74";
$antd-layout-outlined: "\eb75";
$antd-left-circle-filled: "\eb76";
$antd-left-circle-outlined: "\eb77";
$antd-left-outlined: "\eb78";
$antd-left-square-filled: "\eb79";
$antd-left-square-outlined: "\eb7a";
$antd-like-filled: "\eb7b";
$antd-like-outlined: "\eb7c";
$antd-line-chart-outlined: "\eb7d";
$antd-line-height-outlined: "\eb7e";
$antd-line-outlined: "\eb7f";
$antd-link-outlined: "\eb80";
$antd-linkedin-filled: "\eb81";
$antd-linkedin-outlined: "\eb82";
$antd-loading-3quarters-outlined: "\eb83";
$antd-loading-outlined: "\eb84";
$antd-lock-filled: "\eb85";
$antd-lock-outlined: "\eb86";
$antd-login-outlined: "\eb87";
$antd-logout-outlined: "\eb88";
$antd-logout-white-supplement: "\eb89";
$antd-mac-command-filled: "\eb8a";
$antd-mac-command-outlined: "\eb8b";
$antd-mail-filled: "\eb8c";
$antd-mail-outlined: "\eb8d";
$antd-man-outlined: "\eb8e";
$antd-medicine-box-filled: "\eb8f";
$antd-medicine-box-outlined: "\eb90";
$antd-medium-circle-filled: "\eb91";
$antd-medium-outlined: "\eb92";
$antd-medium-square-filled: "\eb93";
$antd-medium-workmark-outlined: "\eb94";
$antd-meh-filled: "\eb95";
$antd-meh-outlined: "\eb96";
$antd-menu-fold-outlined: "\eb97";
$antd-menu-outlined: "\eb98";
$antd-menu-unfold-outlined: "\eb99";
$antd-merge-cells-outlined: "\eb9a";
$antd-message-filled: "\eb9b";
$antd-message-outlined: "\eb9c";
$antd-minus-circle-filled: "\eb9d";
$antd-minus-circle-outlined: "\eb9e";
$antd-minus-outlined: "\eb9f";
$antd-minus-square-filled: "\eba0";
$antd-minus-square-outlined: "\eba1";
$antd-mobile-filled: "\eba2";
$antd-mobile-outlined: "\eba3";
$antd-money-collect-filled: "\eba4";
$antd-money-collect-outlined: "\eba5";
$antd-monitor-outlined: "\eba6";
$antd-more-outlined: "\eba7";
$antd-node-collapse-outlined: "\eba8";
$antd-node-expand-outlined: "\eba9";
$antd-node-index-outlined: "\ebaa";
$antd-notification-filled: "\ebab";
$antd-notification-outlined: "\ebac";
$antd-number-outlined: "\ebad";
$antd-one-to-one-outlined: "\ebae";
$antd-ordered-list-outlined: "\ebaf";
$antd-paper-clip-outlined: "\ebb0";
$antd-partition-outlined: "\ebb1";
$antd-pause-circle-filled: "\ebb2";
$antd-pause-circle-outlined: "\ebb3";
$antd-pause-outlined: "\ebb4";
$antd-pay-circle-filled: "\ebb5";
$antd-pay-circle-outlined: "\ebb6";
$antd-percentage-outlined: "\ebb7";
$antd-phone-filled: "\ebb8";
$antd-phone-outlined: "\ebb9";
$antd-pic-center-outlined: "\ebba";
$antd-pic-left-outlined: "\ebbb";
$antd-pic-right-outlined: "\ebbc";
$antd-picture-filled: "\ebbd";
$antd-picture-outlined: "\ebbe";
$antd-pie-chart-filled: "\ebbf";
$antd-pie-chart-outlined: "\ebc0";
$antd-play-circle-filled: "\ebc1";
$antd-play-circle-grey-supplement: "\ebc2";
$antd-play-circle-outlined: "\ebc3";
$antd-play-square-filled: "\ebc4";
$antd-play-square-grey-supplement: "\ebc5";
$antd-play-square-outlined: "\ebc6";
$antd-plus-circle-blue-supplement: "\ebc7";
$antd-plus-circle-filled-blue-supplement: "\ebc8";
$antd-plus-circle-filled-grey-supplement: "\ebc9";
$antd-plus-circle-filled: "\ebca";
$antd-plus-circle-grey-supplement: "\ebcb";
$antd-plus-circle-outlined: "\ebcc";
$antd-plus-outlined: "\ebcd";
$antd-plus-square-filled: "\ebce";
$antd-plus-square-outlined: "\ebcf";
$antd-plus-white-supplement: "\ebd0";
$antd-pound-circle-filled: "\ebd1";
$antd-pound-circle-outlined: "\ebd2";
$antd-pound-outlined: "\ebd3";
$antd-poweroff-outlined: "\ebd4";
$antd-printer-filled: "\ebd5";
$antd-printer-outlined: "\ebd6";
$antd-profile-filled: "\ebd7";
$antd-profile-outlined: "\ebd8";
$antd-project-filled: "\ebd9";
$antd-project-outlined: "\ebda";
$antd-property-safety-filled: "\ebdb";
$antd-property-safety-outlined: "\ebdc";
$antd-pull-request-outlined: "\ebdd";
$antd-pushpin-filled: "\ebde";
$antd-pushpin-outlined: "\ebdf";
$antd-qq-circle-filled: "\ebe0";
$antd-qq-outlined: "\ebe1";
$antd-qq-square-filled: "\ebe2";
$antd-qrcode-outlined: "\ebe3";
$antd-question-circle-filled: "\ebe4";
$antd-question-circle-outlined: "\ebe5";
$antd-question-outlined: "\ebe6";
$antd-radar-chart-outlined: "\ebe7";
$antd-radius-bottomleft-outlined: "\ebe8";
$antd-radius-bottomright-outlined: "\ebe9";
$antd-radius-setting-outlined: "\ebea";
$antd-radius-upleft-outlined: "\ebeb";
$antd-radius-upright-outlined: "\ebec";
$antd-read-filled: "\ebed";
$antd-read-outlined: "\ebee";
$antd-reconciliation-filled: "\ebef";
$antd-reconciliation-outlined: "\ebf0";
$antd-red-envelope-filled: "\ebf1";
$antd-red-envelope-outlined: "\ebf2";
$antd-reddit-circle-filled: "\ebf3";
$antd-reddit-outlined: "\ebf4";
$antd-reddit-square-filled: "\ebf5";
$antd-redo-outlined: "\ebf6";
$antd-reload-outlined: "\ebf7";
$antd-rest-filled: "\ebf8";
$antd-rest-outlined: "\ebf9";
$antd-retweet-outlined: "\ebfa";
$antd-right-circle-filled: "\ebfb";
$antd-right-circle-outlined: "\ebfc";
$antd-right-outlined: "\ebfd";
$antd-right-square-filled: "\ebfe";
$antd-right-square-outlined: "\ebff";
$antd-rise-outlined: "\ec00";
$antd-robot-filled: "\ec01";
$antd-robot-outlined: "\ec02";
$antd-rocket-filled: "\ec03";
$antd-rocket-outlined: "\ec04";
$antd-rollback-outlined: "\ec05";
$antd-rotate-left-outlined: "\ec06";
$antd-rotate-right-outlined: "\ec07";
$antd-safety-certificate-filled: "\ec08";
$antd-safety-certificate-outlined: "\ec09";
$antd-safety-outlined: "\ec0a";
$antd-save-filled: "\ec0b";
$antd-save-outlined: "\ec0c";
$antd-scan-outlined: "\ec0d";
$antd-schedule-filled: "\ec0e";
$antd-schedule-outlined: "\ec0f";
$antd-scissor-outlined: "\ec10";
$antd-search-outlined: "\ec11";
$antd-security-scan-filled: "\ec12";
$antd-security-scan-outlined: "\ec13";
$antd-select-outlined: "\ec14";
$antd-send-outlined: "\ec15";
$antd-setting-filled: "\ec16";
$antd-setting-outlined: "\ec17";
$antd-shake-outlined: "\ec18";
$antd-share-alt-outlined: "\ec19";
$antd-shop-filled: "\ec1a";
$antd-shop-outlined: "\ec1b";
$antd-shopping-cart-outlined: "\ec1c";
$antd-shopping-filled: "\ec1d";
$antd-shopping-outlined: "\ec1e";
$antd-shrink-outlined: "\ec1f";
$antd-signal-filled: "\ec20";
$antd-sisternode-outlined: "\ec21";
$antd-sketch-circle-filled: "\ec22";
$antd-sketch-outlined: "\ec23";
$antd-sketch-square-filled: "\ec24";
$antd-skin-filled: "\ec25";
$antd-skin-outlined: "\ec26";
$antd-skype-filled: "\ec27";
$antd-skype-outlined: "\ec28";
$antd-slack-circle-filled: "\ec29";
$antd-slack-outlined: "\ec2a";
$antd-slack-square-filled: "\ec2b";
$antd-slack-square-outlined: "\ec2c";
$antd-sliders-filled: "\ec2d";
$antd-sliders-outlined: "\ec2e";
$antd-small-dash-outlined: "\ec2f";
$antd-smile-filled: "\ec30";
$antd-smile-outlined: "\ec31";
$antd-snippets-filled: "\ec32";
$antd-snippets-outlined: "\ec33";
$antd-solution-outlined: "\ec34";
$antd-sort-asc-supplement: "\ec35";
$antd-sort-ascending-outlined: "\ec36";
$antd-sort-desc-supplement: "\ec37";
$antd-sort-descending-outlined: "\ec38";
$antd-sound-filled: "\ec39";
$antd-sound-outlined: "\ec3a";
$antd-split-cells-outlined: "\ec3b";
$antd-star-filled: "\ec3c";
$antd-star-outlined: "\ec3d";
$antd-step-backward-filled: "\ec3e";
$antd-step-backward-outlined: "\ec3f";
$antd-step-forward-filled: "\ec40";
$antd-step-forward-outlined: "\ec41";
$antd-stock-outlined: "\ec42";
$antd-stop-filled: "\ec43";
$antd-stop-outlined: "\ec44";
$antd-strikethrough-outlined: "\ec45";
$antd-subnode-outlined: "\ec46";
$antd-swap-left-outlined: "\ec47";
$antd-swap-outlined: "\ec48";
$antd-swap-right-outlined: "\ec49";
$antd-switcher-filled: "\ec4a";
$antd-switcher-outlined: "\ec4b";
$antd-sync-outlined: "\ec4c";
$antd-table-outlined: "\ec4d";
$antd-tablet-filled: "\ec4e";
$antd-tablet-outlined: "\ec4f";
$antd-tag-filled: "\ec50";
$antd-tag-outlined: "\ec51";
$antd-tags-filled: "\ec52";
$antd-tags-outlined: "\ec53";
$antd-taobao-circle-filled: "\ec54";
$antd-taobao-circle-outlined: "\ec55";
$antd-taobao-outlined: "\ec56";
$antd-taobao-square-filled: "\ec57";
$antd-team-outlined: "\ec58";
$antd-thunderbolt-filled: "\ec59";
$antd-thunderbolt-outlined: "\ec5a";
$antd-to-top-outlined: "\ec5b";
$antd-tool-filled: "\ec5c";
$antd-tool-outlined: "\ec5d";
$antd-trademark-circle-filled: "\ec5e";
$antd-trademark-circle-outlined: "\ec5f";
$antd-trademark-outlined: "\ec60";
$antd-transaction-outlined: "\ec61";
$antd-translation-outlined: "\ec62";
$antd-trophy-filled: "\ec63";
$antd-trophy-outlined: "\ec64";
$antd-twitter-circle-filled: "\ec65";
$antd-twitter-outlined: "\ec66";
$antd-twitter-square-filled: "\ec67";
$antd-underline-outlined: "\ec68";
$antd-undo-outlined: "\ec69";
$antd-ungroup-outlined: "\ec6a";
$antd-unlock-filled: "\ec6b";
$antd-unlock-outlined: "\ec6c";
$antd-unordered-list-outlined: "\ec6d";
$antd-up-circle-filled: "\ec6e";
$antd-up-circle-outlined: "\ec6f";
$antd-up-outlined: "\ec70";
$antd-up-square-filled: "\ec71";
$antd-up-square-outlined: "\ec72";
$antd-upload-outlined: "\ec73";
$antd-usb-filled: "\ec74";
$antd-usb-outlined: "\ec75";
$antd-user-add-outlined: "\ec76";
$antd-user-delete-outlined: "\ec77";
$antd-user-outlined: "\ec78";
$antd-user-switch-outlined: "\ec79";
$antd-usergroup-add-outlined: "\ec7a";
$antd-usergroup-delete-outlined: "\ec7b";
$antd-verified-outlined: "\ec7c";
$antd-vertical-align-bottom-outlined: "\ec7d";
$antd-vertical-align-middle-outlined: "\ec7e";
$antd-vertical-align-top-outlined: "\ec7f";
$antd-vertical-left-outlined: "\ec80";
$antd-vertical-right-outlined: "\ec81";
$antd-video-camera-add-outlined: "\ec82";
$antd-video-camera-filled: "\ec83";
$antd-video-camera-outlined: "\ec84";
$antd-wallet-filled: "\ec85";
$antd-wallet-outlined: "\ec86";
$antd-warning-filled: "\ec87";
$antd-warning-outlined: "\ec88";
$antd-wechat-filled: "\ec89";
$antd-wechat-outlined: "\ec8a";
$antd-weibo-circle-filled: "\ec8b";
$antd-weibo-circle-outlined: "\ec8c";
$antd-weibo-outlined: "\ec8d";
$antd-weibo-square-filled: "\ec8e";
$antd-weibo-square-outlined: "\ec8f";
$antd-whats-app-outlined: "\ec90";
$antd-wifi-outlined: "\ec91";
$antd-windows-filled: "\ec92";
$antd-windows-outlined: "\ec93";
$antd-woman-outlined: "\ec94";
$antd-yahoo-filled: "\ec95";
$antd-yahoo-outlined: "\ec96";
$antd-youtube-filled: "\ec97";
$antd-youtube-outlined: "\ec98";
$antd-yuque-filled: "\ec99";
$antd-yuque-outlined: "\ec9a";
$antd-zhihu-circle-filled: "\ec9b";
$antd-zhihu-outlined: "\ec9c";
$antd-zhihu-square-filled: "\ec9d";
$antd-zoom-in-outlined: "\ec9e";
$antd-zoom-out-outlined: "\ec9f";
