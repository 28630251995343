/* stylelint-disable */
@import "../../font/index.scss";
@import 'variables';
@import 'mixins';

ul,
li,
ol,
li {
  list-style: none;
}

html {
  font-size: 12px;
  height: 100%;
}

.clearfix {
  clear: both;
  zoom: 1;
}

span:focus {
  outline: none;
}

body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  color: #333;
  min-width: 1024px;
}

input {
  outline: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }
}

label {
  margin-bottom: 0.5rem;
}

.input-control {
  height: 32px;
  padding: 6px 12px;
  font-size: 12px;
}

input[type='text'],
input[type='password'],
textarea,
select {
  background-color: #fff;
  border: 1px solid $silver;
  border-radius: 3px;
  color: black;

  &.disabled {
    pointer-events: none;
    background: $btn-disabled;
  }

  &:invalid {
    color: #a9a9a9;
  }
}

.select-wrapper {
  display: inline-block;
  position: relative;

  &:before {
    @extend .fa;
    @extend .antd-caret-down-outlined;

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    margin-top: -2px;
    margin-right: 10px;
    padding-top: 6px;
    color: #a9a9a9;
    font-size: 14px;
    text-align: right;
    width: 22px;
    height: 22px;
    pointer-events: none;
  }

  &.disabled:before {
    background: $btn-disabled;
    content: '';
  }

  select {
    appearance: none;
    padding-right: 24px;

    &::-ms-expand {
      display: none;
    }
  }
}

[hidden],
[data-hidden='true'] {
  display: none;
}

.btn-large {
  width: 244px;
  height: 60px;
  font-size: 18px;
}

.btn-reject {
  color: $cerulean;
  background-color: transparent;
  font-weight: normal;

  &:hover {
    color: $lochmara;
  }
}

.g-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-button {
  }

  &::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background-clip: padding-box;
    background-color: whitesmoke;
    border: solid white;
  }

  &::-webkit-scrollbar-track-piece:vertical {
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.14), inset -1px 0 0 rgba(0, 0, 0, 0.05);
    border-width: 0;
  }

  &::-webkit-scrollbar-track-piece:horizontal {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.14), inset 0 -1px 0 rgba(0, 0, 0, 0.05);
    border-width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border: solid transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-width: 1px 1px 1px 2px;
    min-height: 24px;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-width: 2px 1px 1px 1px;
    min-width: 24px;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.5), inset -1px 0 0 rgba(0, 0, 0, 0.3);
  }
}

.table-customization {
  font-size: 12px;

  thead {
    th {
      color: #fff;
      background: #333;
      border: none;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #fff;
      }

      &:nth-of-type(even) {
        background-color: $wild-sand;
      }
    }
  }

  tr {
    height: 40px;

    th:first-child {
      padding-left: 20px;
    }

    td:first-child {
      padding-left: 20px;
    }
  }

  td {
    border: none;
    text-align: center;
    vertical-align: middle;
  }
}

.thead {
  .th {
    color: #fff;
    background: #333;
    border: none;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
  }
}

.tbody {
  color: $black;

  .tr {
    &:nth-of-type(odd) {
      background-color: #fff;
    }

    &:nth-of-type(even) {
      background-color: $wild-sand;
    }
  }
}

.tr {
  height: 40px;

  .th:first-child {
    padding-left: 20px;
  }

  .td:first-child {
    padding-left: 20px;
  }
}

.td {
  border: none;
  text-align: center;
  vertical-align: middle;
}

.btn {
  white-space: nowrap;
}
.btn-high {
  height: 32px;
}
.btn-hollow,.btn-outline {
  background-color: #fff;
  border-radius: 3px;
  border: solid 1px $cerulean;
  padding: 6px 16px;
  font-size: 1rem;
  font-weight: normal;
  color: $cerulean;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: $cerulean;
  }

  &.disabled-btn:hover {
    cursor: not-allowed;
    background-color: #fff;
  }

  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}
.btn-outline {
  padding: 6px 12px;
}
.btn-weight-normal {
  font-weight: normal;
}
.btn-weight-bold {
  font-weight: bold;
}
.btn.btn-md,
.btn.btn-sm {
  width: auto;
  height: 32px;
  font-size: 14px;
  line-height: 18px;
}
.btn.btn-md {
  min-width: 88px;
  padding: 6px 16px;
}
.btn.btn-sm {
  min-width: 52px;
  padding: 6px 11px;
}

img {
  vertical-align: middle;
}

svg {
  vertical-align: baseline;
}

// TODO: remove when find better method
.icon-add {
  margin-right: 4px;
}

.icon {
  padding-top: 1px;
}

.icon-add-slim {
  padding-top: 3px;
  margin-bottom: -1px;
  margin-right: -3px;
}

.icon-right-space {
  margin-right: 1px;
}

.table {
  border-collapse: collapse;
  background-color: transparent;
  display: table;
  border-spacing: 2px;
  border-color: grey;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.th,
.td {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal-header {
  background-color: $pattens-blue;
  padding: 15px 30px;

  .btn-close {
    line-height: normal;
    opacity: 1;

    &:hover {
      opacity: 0.5;
    }
  }
}

.disabled-area {
  color: $silver;
  border-color: $silver;
}

.hierarchy-operation-area {
  width: 100%;
  position: relative;
  padding: 22px 36px;

  span {
    color: $gray-darker;
  }

  .warning {
    color: $cinnabar;
  }
}

::ng-deep .tooltip-inner {
  font-size: 10px;
  color: #333;
  background-color: #fff;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.1);
  text-align: left;

  &:before {
    border: none;
  }
}

::ng-deep .tooltip {
  &.in {
    opacity: 1;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  background-color: $white;
  border: 1px solid $silver;
  border-radius: 3px;
  color: black;
}

.element-center,
.element-center-x,
.element-center-y {
  position: absolute;
}

.element-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.element-center-x {
  left: 50%;
  transform: translateX(-50%);
}

.element-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.word-line-break {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.customize-textarea {
  height: auto;
  width: 100%;
  border: 1px solid $silver;
  border-radius: 3px;
  padding: 0 12px;

  &:focus {
    background-color: #fff;
    border-color: $malibu;
    outline: none;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

textarea::-webkit-input-placeholder {
  color: $silver;
  opacity: 1 !important;
}

textarea:-moz-placeholder {
  color: $silver;
  opacity: 1 !important;
}

textarea::-moz-placeholder {
  color: $silver;
  opacity: 1 !important;
}

textarea:-ms-input-placeholder {
  color: $silver;
  opacity: 1 !important;
}

.required {
  &:after {
    content: '*';
    color: $cinnabar;
    margin-left: 4px;
    margin-right: 10px;
  }
}

// fix form-control in select native style miss err
select.form-control:not(.form-select) {
  appearance: auto;
}

.select-wrapper select.form-control {
  appearance: none;
}

input.search-input:focus {
  box-shadow: none;
}
/* stylelint-enable */

/* align radio & text in a line */
input[type='radio'] {
  vertical-align: text-bottom;
}
